import React, { useState, useEffect, useRef } from 'react';
import Button from '../../components/bootstrap/Button';
import Modal, {
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from '../../components/bootstrap/Modal';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import { ChecksGroup } from '../../components/bootstrap/forms/Checks';
import { updateProjectDetail, getTechnologies } from '../../common/data/service';
import { useFormik } from 'formik';
import Badge from '../../components/bootstrap/Badge';
import Icon from '../../components/icon/Icon';
import SortableList, { SortableItem } from 'react-easy-sort';
import { arrayMoveImmutable } from 'array-move';
import useDarkMode from "../../hooks/useDarkMode";
import { MultiSelect } from "react-multi-select-component";
import classNames from "classnames";
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const Technologies = (props: any) => {
  const { projectId, projectDetailsValues, setIsLoading, aedAccess } = props
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const flag = useRef(true);
  const [confirmationStatus, setConfirmationStatus] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [techList, setTechList] = useState<any[]>([]);
  const [editTech, setEditTech] = useState(false);
  const { darkModeStatus } = useDarkMode();
  const [alert, setAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const handleSubmit = async () => {
    setIsSubmitted(true);
    await formik.setFieldValue("technologies", formik.values.technologies.concat(selectedList.map(item => (item as any).label)));
    formik.submitForm();
    if (selectedList.length > 0) {
      setIsEditOpen(false);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000)
    }
  }
  const handleClose = () => {    
    setIsEditOpen(false);
    setIsSubmitted(false);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    await formik.setFieldValue("technologies", formik.values.technologies.filter((tech, tech_index) => { return tech_index != currentIndex }))
    setAlertMessage('Deleted Successfully');
    formik.submitForm();
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000)
  }

  const formik = useFormik({
    onSubmit: (values) => {
      if (selectedList.length > 0 || editTech){
        updateProjectDetail({
          projectId: values.projectId,
          aedTechnologies: values.technologies
        }).catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
        });
      }
      setIsLoading(false);
    },
    initialValues: {
      projectId: projectId,
      technologies: [""]
    },
    validate: (values) => {
      const errors: any = {};   
      if (isSubmitted && selectedList.length === 0) {
        errors.technologies = "Please select at least one technology.";
        setIsLoading(false)
      }
      return errors;
    }
  })

  const onSortEnd = async (oldIndex: number, newIndex: number) => {
    await formik.setFieldValue("technologies", arrayMoveImmutable(formik.values.technologies, oldIndex, newIndex));
    setAlertMessage('Updated Successfully')
    formik.submitForm();
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000)
  }

  useEffect(() => {
    if (flag.current && Object.keys(projectDetailsValues).length > 0) {
      flag.current = false;
      formik.setFieldValue("technologies", (projectDetailsValues?.data?.data[0]?.aed_technologies_json || []))
      getTechnologies().then((res) => {
        setTechList(res?.data.map((val: any) => { return { label: val.name, value: val.name } }))
      }).catch((e) => {
        if (!validateExpiredToken(e.message)) {
          navigate('/');
          window.location.reload();
        }
      });
    }
    (isEditOpen || confirmationStatus) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [projectId, formik, isEditOpen, confirmationStatus, projectDetailsValues, navigate])

  return (
    <div>
      <div className="card">
        <div className="card-header">
        <div className="row g-3 align-items-center">
          <div className="col d-flex align-items-center">
            <div className="flex-shrink-0">
              <div className="ratio ratio-1x1 aed-icon-box">
                <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-warning" : "bg-l10-warning")}>
                  <label
                    className="border-0 bg-transparent me-0"
                    htmlFor="Settings">
                    <Icon icon="Settings" size="2x" color='warning' />
                  </label>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
              <div>
                <div className="fw-bold fs-6 mb-0">
                  <h5 className="card-title">Technologies</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
          <div className="card-actions">
            {!editTech && aedAccess?.edit && <Button
              color="info"
              icon={"Add"}
              onClick={() => {
                setIsEditOpen(true);
                setSelectedList([]);
              }}
            >
              Add
            </Button>}

            {aedAccess?.edit && <Button
              color= "dark"
              isOutline={!darkModeStatus}
              isLight={darkModeStatus}
              className={classNames("text-nowrap", {
                "border-light": !darkModeStatus,
              })}
              onClick={() => {
                setEditTech(!editTech)
              }}
              icon={editTech ? "Close" : "Edit"}
              isDisable={!editTech && (!formik.values.technologies || formik.values.technologies.length === 0)}
            >
            </Button>}
          </div>
        </div>
        <div className="card-body">
          <SortableList onSortEnd={onSortEnd} allowDrag={editTech} className="tech-list">
            {formik.values.technologies.map((item, index) => (
              <SortableItem key={index}>
                <div key={index} className="tech-item">
                  <Badge
                    isLight
                    className={editTech ? "fs-6 tech-tag-edit" : "fs-6 tech-tag"}
                  >
                    {item}
                    {editTech && <Icon
                      icon={"Close"}
                      size="lg"
                      className="cursor-pointer tech-delete-icon"
                      onClick={async () => {
                        setCurrentIndex(index);
                        setConfirmationStatus(true);
                      }}
                    />}
                  </Badge>
                </div>
              </SortableItem>
            ))}
          </SortableList>
        </div>
      </div>

      <Modal isOpen={isEditOpen}
        setIsOpen={setIsEditOpen}
        titleId="tour-title"
        isCentered={true}
        isStaticBackdrop
      >
        <ModalHeader setIsOpen={setIsEditOpen}>
          <ModalTitle id="tour-title" className="d-flex align-items-end">
            Technologies
          </ModalTitle>
          <Button
						color= {darkModeStatus ? 'dark' : 'light'}
            onClick={() => handleClose()}
            className={darkModeStatus ? 'modal-close-btn-dark' : 'modal-close-btn'}
            icon="Close"
          />
        </ModalHeader>
        <ModalBody>

          <FormGroup className="col-12">
            <ChecksGroup>
              <MultiSelect
                options={techList.filter(option => !formik.values.technologies.includes(option.label))}
                value={selectedList}
                onChange={setSelectedList}
                labelledBy="Select"
                isCreatable={true}
                hasSelectAll={false}
                className={darkModeStatus ? "multi-select-dark" : ""}
              />
            </ChecksGroup>
            <div>
              {selectedList.length === 0 && formik.errors.technologies && isSubmitted && (
                <div className='aed-technology-invalid'>{formik.errors.technologies}</div>
              )}
            </div>
            <Button
              color="info"
              className="tech-submit"
              onClick={async () => {
                setIsLoading(true);
                setAlertMessage('Saved Successfully');
                handleSubmit();
              }}>Save
            </Button>
          </FormGroup>
        </ModalBody>
      </Modal>

      <Modal
        setIsOpen={setConfirmationStatus}
        isOpen={confirmationStatus}
        titleId="Delete Confirmation"
        isStaticBackdrop
      >
        <ModalHeader setIsOpen={setConfirmationStatus}>
          <ModalTitle id="new-todo-modal">
            Delete Confirmation
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
            <Button
              color="danger"
              onClick={() => {
                handleDelete();
                setConfirmationStatus(false);
              }}
            >
              Delete
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button color="success" onClick={() => setConfirmationStatus(false)}>
              Cancel
            </Button>
        </ModalFooter>
      </Modal>
      {
        alert &&
          <Alert
          icon=''
          isLight
          color='primary'
          borderWidth={0}
          className='cgp-container-alert'
        >
          <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
            {alertMessage}
          </AlertHeading>
        </Alert>
      }
    </div>
  )
}

export default Technologies
