import React, { useState, useEffect } from "react";
import useDarkMode from "../../hooks/useDarkMode";
import Button from "../../components/bootstrap/Button";
import classNames from "classnames";
import { useFormik, FormikValues } from "formik";
import Modal, { ModalBody, ModalHeader, ModalTitle, ModalFooter } from "../../components/bootstrap/Modal";
import Input from "../../components/bootstrap/forms/Input";
import { getSelectedProjectDetails, updateProjectDetail } from "../../common/data/service";
import FormGroup from "../../components/bootstrap/forms/FormGroup";
import Popovers from "../../components/bootstrap/Popovers";
import Textarea from '../../components/bootstrap/forms/Textarea';
import { ArchitectureComponents } from "./types";
import Icon from "../../components/icon/Icon";
import Alert, { AlertHeading } from '../../components/bootstrap/Alert';
import { validateExpiredToken } from '../../App/Constants';
import { useNavigate } from 'react-router-dom';

const ArchComp = (props: any) => {
  const { projectId, archCompDetails, setArchCompDetails, setIsLoading, aedConstant, aedAccess } = props;
  const { ARCH_COMP_LIMIT } = aedConstant;
  const { darkModeStatus } = useDarkMode();
  const [editIndex, setEditIndex] = useState("");  
  const [deleteConfirmationPopup, setDeleteConfirmationPopup] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState<string>("");
  const [editArchComp, setEditArchComp] = useState(false);
  const [addArchComp, setAddArchComp] = useState(false);
  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [alert, setAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const navigate = useNavigate();
  const toggleRowExpand = (index: string) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([index]);
    }
  };
  const formik = useFormik({
    onSubmit: (values) => {
      let isValid = false;
      if (values.title && values.description) {
        isValid = true;
      }
      if(isValid){
        setIsLoading(true);
        updateProjectDetail({
          projectId: projectId,
          aedArchComp: values.aedArchCompJson,
        }).catch((e) => {
          if (!validateExpiredToken(e.message)) {
            navigate('/');
            window.location.reload();
          }
        });
        setArchCompData();
        handleClose();
      }
    },
    initialValues: {
      aedArchCompJson: archCompDetails,
      title: "",
      description: ""
    },
    validate: (values) => {
      const errors: Partial<FormikValues> = {};
      const isDuplicateTitle = archCompDetails?.architectures?.some((archCompList: { title: string; }, index: string) => {
				if (editArchComp) {
					return index !== editIndex && archCompList.title.toLowerCase() === values.title.toLowerCase()
				} else {
					return archCompList.title.toLowerCase() === values.title.toLowerCase()
				}
			  });
			  const isDuplicateDescription = archCompDetails?.architectures?.some((archCompList: { description: string; }, index: string) => {
				if (editArchComp) {
					return index !== editIndex && archCompList.description.toLowerCase() === values.description.toLowerCase()
				} else {
					return archCompList.description.toLowerCase() === values.description.toLowerCase()
				}
			  });
      if (isDuplicateDescription) {
        errors.description = "Description must be unique";
      }
      if (isDuplicateDescription) {
        errors.description = "Description must be unique";
      }
      if (isDuplicateTitle) {
        errors.title = "Architecture or Component must be unique";
      }
      if (!values.title) {
          errors.title = "Architecture or Component is required";
      }
      if (!values.description) {
          errors.description = "Description is required";
      }
      return errors;
    },
  });
  const setArchCompData = async () =>  {
    await getSelectedProjectDetails({ projectId }).then((res) => {
      setArchCompDetails(res?.data?.data[0]?.aed_arch_json)
      setIsLoading(false);
    })
    setAlert(true);
    setTimeout(() => {
      setAlert(false);
    }, 3000)
  };
  const handleDelete = (index: string) => {
    delete archCompDetails.architectures[index];
    updateProjectDetail({
      projectId: projectId,
      aedArchComp: archCompDetails,
    });
    setAlertMessage('Deleted Successfully');
  };
  const handleClose = () => {
    setAddArchComp(false);
    formik.setFieldValue("title", "");
    formik.setFieldValue("description", "");
    formik.resetForm();
  };
  const handleSave = () => {
    const newArchComp = { title: formik.values.title, description: formik.values.description };
    const updatedArchComp = archCompDetails.architectures ? [...archCompDetails.architectures, newArchComp] : [newArchComp];
    const aedArchComp = { architectures: updatedArchComp };
    formik.setFieldValue("aedArchCompJson", aedArchComp);
    setAlertMessage('Saved Successfully');
  };
  const handleEdit = () => {
    const updatedRoles = [...archCompDetails.architectures];
    updatedRoles[parseInt(editIndex)] = { title: formik.values.title, description: formik.values.description };
    const aedArchComp = { architectures: updatedRoles };
    formik.setFieldValue("aedArchCompJson", aedArchComp);
    setAlertMessage('Updated Successfully');
  };

  useEffect(() => {
    (addArchComp || deleteConfirmationPopup) ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'unset';
  }, [addArchComp, deleteConfirmationPopup])
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div className="row g-3 align-items-center">
            <div className="col d-flex align-items-center">
              <div className="flex-shrink-0">
                <div className="ratio ratio-1x1 aed-icon-box">
                  <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-success" : "bg-l10-success")}>
                    <label
                      className="border-0 bg-transparent me-0"
                      htmlFor="Architecture">
                      <Icon icon="Architecture" size="2x" color='success' />
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                <div>
                  <div className="fw-bold fs-6 mb-0">
                    <h5 className="card-title">Architectural Components</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-actions">
            {!editArchComp && aedAccess?.edit &&
              <Button
                icon="Plus"
                color="info"
                onClick={() => {
                  setAddArchComp(true);
                }}
              >
                Add
              </Button>
            }
            {aedAccess?.edit && <Button
              isOutline={!darkModeStatus}
              color={"dark"}
              isLight={darkModeStatus}
              className={classNames("text-nowrap", {
                "border-light": !darkModeStatus,
              })}
              onClick={() => setEditArchComp(!editArchComp)}
              icon={editArchComp ? "Close" : "Edit"}
              isDisable={!editArchComp && (!archCompDetails || !archCompDetails.architectures || archCompDetails.architectures.length === 0)}
            />}
          </div>
        </div>
        <div className="card-body">
          <div className="aed-card">
            {archCompDetails && archCompDetails.architectures && archCompDetails.architectures.length > 0 && (
              <table className="table table-modern table-hover">
                <tbody>
                  {archCompDetails.architectures.map((archCompList: ArchitectureComponents, index: string) => (
                    <tr key={index}>
                      <td className="col-lg-3">
                        <div className="aed-table">
                          {archCompList.title}
                        </div>
                      </td>
                      <td className="col-lg-7">
                        <div className={`cell-content ${expandedRows.includes(index) ? 'expanded' : ''}`}>
                          {archCompList.description.length > ARCH_COMP_LIMIT && !expandedRows.includes(index) ? (
                            <>
                              {archCompList.description.slice(0, ARCH_COMP_LIMIT)}{" "}
                              <span className="read-more" onClick={() => toggleRowExpand(index)}>
                                {"...Read more"}
                              </span>
                            </>
                          ) : (
                            <>
                              {archCompList.description}
                              {archCompList.description.length > ARCH_COMP_LIMIT && expandedRows.includes(index) && (
                                <span className="read-more" onClick={() => toggleRowExpand(index)}>
                                  {"...Read less"}
                                </span>
                              )}
                            </>
                          )}
                        </div>
                      </td>
                      <td className="col-lg-2">
                        {editArchComp &&
                          <div className="col-lg align-items-center">
                            <Popovers trigger='hover' desc='Delete'>
                              <Button
                                isOutline={!darkModeStatus}
                                color="dark"
                                isLight={darkModeStatus}
                                className={classNames("text-nowrap", {
                                  "border-light": !darkModeStatus,
                                })}
                                icon="Delete"
                                onClick={() => {
                                  setDeleteConfirmationPopup(true);
                                  setDeleteRecordId(index);
                                }}
                              />
                            </Popovers>
                            <Popovers trigger='hover' desc='Edit'>
                              <Button
                                isOutline={!darkModeStatus}
                                color="dark"
                                isLight={darkModeStatus}
                                className={classNames("text-nowrap", {
                                  "border-light": !darkModeStatus,
                                })}
                                icon="Edit"
                                onClick={() => {
                                  formik.setFieldValue("title", archCompList.title);
                                  formik.setFieldValue("description", archCompList.description);
                                  setAddArchComp(true);
                                  setEditIndex(index);
                                }}
                              />
                            </Popovers>
                          </div>
                        }
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      <Modal
        setIsOpen={setAddArchComp}
        isOpen={addArchComp}
        size="lg"
        isCentered
        isStaticBackdrop={true}
      >
        <ModalHeader setIsOpen={setAddArchComp}>
          <ModalTitle id="addArchitectureComponents">
            <div>
              {editArchComp ? (
                <h5>Edit Architectural Components</h5>
              ) : (
                <h5>Add Architectural Components</h5>
              )}
            </div>
          </ModalTitle>
          <Button
						color= {darkModeStatus ? 'dark' : 'light'}
            onClick={() => handleClose()}
            className={darkModeStatus ? 'modal-close-btn-dark' : 'modal-close-btn'}
            icon="Close"
          />
        </ModalHeader>
        <ModalBody>
          <div className="row g-3">
            <div className="col-12 p-2">
              <FormGroup
                id='title'
                label='Architecture or Component'
                isRequired={true}
                labelClassName="label-font-color"
              >
                <Input
                  id="title"
                  type="text"
                  className="form-control processvine-form-font-weight"
                  onChange={formik.handleChange}
                  value={formik.values.title}
                  isValid={formik.isValid && formik.touched.title}
                  isTouched={formik.touched.title}
                  onBlur={formik.handleBlur}
                  invalidFeedback={formik.errors.title}
                />
              </FormGroup>
            </div>
            <div className="col-12 p-2">
              <FormGroup
                id='description'
                label='Description'
                isRequired={true}
                labelClassName="label-font-color"
              >
                <Textarea
                  id="description"
                  className="form-control processvine-form-font-weight"
                  onChange={formik.handleChange}
                  value={formik.values.description}
                  isValid={formik.isValid && formik.touched.description}
                  isTouched={formik.touched.description}
                  onBlur={formik.handleBlur}
                  invalidFeedback={formik.errors.description}
                  rows={8}
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="info"
            onClick={() => {
              editArchComp ? handleEdit() : handleSave();
              formik.handleSubmit();
            }}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        setIsOpen={setDeleteConfirmationPopup}
        isOpen={deleteConfirmationPopup}
        titleId="Delete Confirmation"
        isStaticBackdrop={true}
      >
        <ModalHeader setIsOpen={setDeleteConfirmationPopup}>
          <ModalTitle id="new-todo-modal">
            Delete Confirmation
          </ModalTitle>
        </ModalHeader>
        <ModalBody>
            Are you sure you want to delete?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={async () => {
              setIsLoading(true);
              handleDelete(deleteRecordId);
              setDeleteConfirmationPopup(false);
              setArchCompData();
            }}
          >
            Delete
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button color="success" onClick={() => setDeleteConfirmationPopup(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      {
        alert &&
          <Alert
          icon=''
          isLight
          color='primary'
          borderWidth={0}
          className='cgp-container-alert'
        >
          <AlertHeading tag='h2' className={classNames('text-sm cgp-container-alertHeading')}>
            {alertMessage}
          </AlertHeading>
        </Alert>
      }
    </div>
  )
}

export default ArchComp
