import React from 'react';
import Button from "../../components/bootstrap/Button";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import { useEffect, useState } from "react";
import Icon from "../../components/icon/Icon";
import { generateCaseStudy } from "../../common/data/service";
import DOMPurify from "dompurify";
const CaseStory = (props: any) => {
    const { projectId, setIsLoading, aedAccess, projectDetailsValues } = props;
    const { darkModeStatus } = useDarkMode();
    const [caseStory, setCaseStory] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const extractTitleAndContent = (data: string): { title: string; content: string } => {
        const decodedData = decodeUnicode(data).replace(/^"|"$/g, '');
        const [innerTitle, ...contentArray] = decodedData.split('\\n');
        const content = contentArray.join('\\n');

        return { title: innerTitle, content };
    };
    const formatCaseStudyData = (data: string): string => {
        return data
            .replace(/\\n/g, '<br>')  // Replace newline characters with <br> for line breaks
            .replace(/(Overview:|Challenge:|Approach:|Impact Delivered:|Conclusion:)/gi, (match) => `<strong>${match}</strong><br>`) // Wrap section titles in <strong> tags and add a <br> after them
            .replace(/\n/g, '<br>'); // Replace remaining newline characters with <br> for proper formatting
    };

    const processCaseStudyData = (data: string) => {
        const decodedData = decodeURIComponent(data); // Ensure decoding of any encoded characters
        const lines = decodedData.split('\n\n'); // Split by double newlines to separate title and content
        const innerTitle = lines[0];
        const content = lines.slice(1).join('\n\n');
        setTitle(innerTitle);
        setCaseStory(formatCaseStudyData(content));
    };

    useEffect(() => {
        if (Object.keys(projectDetailsValues).length > 0) {
            const caseStudyData = projectDetailsValues?.data?.data[0]?.aed_case_study_json || "";
            processCaseStudyData(caseStudyData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projectDetailsValues])
    const decodeUnicode = (str: string): string => {
        return str.replace(/\\u[\dA-F]{4}/gi, (match) => {
            return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
        });
    };

    const handleGenerateClick = async () => {
        setIsLoading(true);
        try {
            setIsLoading(true)
            const response = await generateCaseStudy({ projectId: projectId })
            processCaseStudyData(response.data);
        } catch (err) {
            console.error(err);
        } finally {
            setIsLoading(false)
        }
    };
    const sanitizeData = (data: any) => {
        return DOMPurify.isSupported ? DOMPurify.sanitize(data, { ADD_ATTR: ['target'] }) : ''
    }
    return (
        <div>
            <div className="card">
                <div className="card-header">
                    <div className="row g-3 align-items-center">
                        <div className="col d-flex align-items-center">
                            <div className="flex-shrink-0">
                                <div className="ratio ratio-1x1 aed-icon-box">
                                    <div className={classNames("rounded-2 d-flex align-items-center justify-content-center", darkModeStatus ? "bg-lo25-primary" : "bg-l10-primary")}>
                                        <label
                                            className="border-0 bg-transparent me-0"
                                            htmlFor="Link">
                                            <Icon icon="ManageSearch" size="2x" color='primary' />
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1 ms-3 d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="fw-bold fs-6 mb-0">
                                        <h5 className="card-title">Case Study</h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-actions'>
                    {aedAccess?.edit &&
                        <Button
                            icon='print'
                            color="info"
                            onClick={() => {
                                handleGenerateClick()
                            }}>
                            {caseStory.length > 0 ? 'Regenerate' : 'Generate'}
                        </Button>}
                    </div>
                </div>

                <div className="card-body">
                    <h5 style={{ textAlign: "center" }}> {title}</h5>
                    <div
                        className="aed-card-description"
                        dangerouslySetInnerHTML={{ __html: sanitizeData(caseStory) }}
                    />

                </div>
            </div>
        </div>
    )
}

export default CaseStory
